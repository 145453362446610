<template>
  <b-tabs v-if="employeeMainTabsOptions" content-class="">
    <b-tab
      v-for="(option, index) in employeeMainTabsOptions"
      :key="index"
      @click="goTo(option.to)"
      :title-item-class="'custom-tab-outer-li'"
      :active="currentTab == option.currentTab ? true : false"
    >
      <!-- :tab="props: { active: true }" -->
      <template #title>
        <span v-b-popover.hover.bottomright="`${option.itemName}`">
          <img :src="`${option.itemImagePath}`" class="icon-tab" />
          <span>{{ option.itemName }}</span></span
        >
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import imgEmployeeInfo from "@/assets/images/man.svg";
import imgEmployeeFolders from "@/assets/images/media.svg";
import imgEmployeeBranches from "@/assets/images/branches.svg";
import imgEmployeeGrant from "@/assets/images/grants.svg";
import imgEmployeeBalancesUseds from "@/assets/images/balances-plans.svg";
import imgEmployeeWorkContracts from "@/assets/images/contracts.svg";
import imgJobAssignmentOrders from "@/assets/images/orders.svg";
import { checkPrivilege } from "@/utils/functions";
import UsersEstablishmentPrivilege from "@/models/privileges/establishmentPrivileges/UsersEstablishmentPrivilege";

export default {
  name: "EmployeeDataTabs",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      usersEstablishmentPrivilegeData:
        this.$store.getters.usersEstablishmentPrivilegeData ||
        new UsersEstablishmentPrivilege(),
      currentTab: "",
      employeeMainTabsOptions: [
        {
          to: { name: "EmployeeInfo" },
          itemName: this.$t("employeeTabs.mainTabs.modelName"),
          itemImagePath: imgEmployeeInfo,
          currentTab: "employeeInfo",
        },
      ],
    };
  },
  methods: {
    checkPrivilege,
    setEmployeeMainTabsOptions() {
      // check privilege to create tabs
      if (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeFolders
            .employeeFoldersView
        )
      ) {
        this.employeeMainTabsOptions.push({
          to: { name: "EmployeeFolders" },
          itemName: this.$t("employeeTabs.media"),
          itemImagePath: imgEmployeeFolders,
          currentTab: "employeeMedia",
        });
      }
      if (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
            .employeeBranchesView
        )
      ) {
        this.employeeMainTabsOptions.push({
          to: {
            name: "EmployeeBranchesTab",
            params: {
              employeeToken:
                this.employeeToken || this.$store.getters.employeeToken,
            },
          },
          itemName: this.$t("employeeTabs.sectors-branches"),
          itemImagePath: imgEmployeeBranches,
          currentTab: "employeeBranches",
        });
      }
      if (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesGrants
            .employeesGrantsView
        )
      ) {
        this.employeeMainTabsOptions.push({
          to: {
            name: "EmployeeGrantsTab",
            params: {
              employeeToken:
                this.employeeToken || this.$store.getters.employeeToken,
            },
          },
          itemName: this.$t("employeeGrants.modelName"),
          itemImagePath: imgEmployeeGrant,
          currentTab: "employeeGrant",
        });
      }
      this.employeeMainTabsOptions.push({
        to: {
          name: "EmployeeBalancesUseds",
          params: {
            employeeToken:
              this.employeeToken || this.$store.getters.employeeToken,
          },
        },
        itemName: this.$t("EmployeeBalancesUseds.modelName"),
        itemImagePath: imgEmployeeBalancesUseds,
        currentTab: "EmployeeBalancesUseds",
      });
      if (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
        )
      ) {
        this.employeeMainTabsOptions.push({
          to: {
            name: "EmployeeWorkContracts",
            params: {
              employeeToken:
                this.employeeToken || this.$store.getters.employeeToken,
            },
          },
          itemName: this.$t("employeeTabs.workContracts"),
          itemImagePath: imgEmployeeWorkContracts,
          currentTab: "employeeWorkContracts",
        });
      }
      if (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeJobAssignmentOrders
            .jobAssignmentOrdersView
        )
      ) {
        this.employeeMainTabsOptions.push({
          to: {
            name: "JobAssignmentOrders",
            params: {
              employeeToken:
                this.employeeToken || this.$store.getters.employeeToken,
            },
          },
          itemName: this.$t("employeeTabs.workOrder"),
          itemImagePath: imgJobAssignmentOrders,
          currentTab: "jobAssignmentOrders",
        });
      }
    },
    goTo(path) {
      this.$router.push(path).catch(() => {});
    },
    getPageInfo() {
      this.currentTab = this.$route.meta.currentTab || "";
    },
  },
  computed: {},
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  created() {
    this.setEmployeeMainTabsOptions();
    this.getPageInfo();
  },
};
</script>
