<template>
  <div class="user-card-info">
    <span v-b-toggle.employeeInfo class="collapse-title">
      {{ $t("employeeInfo") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </span>
    <b-collapse visible id="employeeInfo">
      <div class="row">
        <div class="user-img-container col-lg-4">
          <img
            class="user-img"
            :src="BASE_URL + employeeData.employeeImagePath"
            :onerror="`this.src='${DEFAULT_IMG}'`"
          />
        </div>
        <div class="col-lg-4 col-md-6">
          <DataLabelGroup
            :value="employeeData.employeeCode"
            :title="$t('employeeCode')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :value="employeeData.employeeNameCurrent"
            :title="$t('employeeName')"
            :imgName="'man.svg'"
          />
          <DataLabelGroup
            :class="'phone-number'"
            :value="employeeData.employeePhoneWithCC"
            :title="$t('phoneNumber')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :value="employeeData.employeeEmail"
            :title="$t('email')"
            :imgName="'email.svg'"
          />
        </div>
        <div class="col-lg-4 col-md-6">
          <DataLabelGroup
            :value="
              employeeData.employeeActiveStatus == true ? $t('yes') : $t('no')
            "
            :title="$t('employeeActiveStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :value="employeeData.employeeBirthdate"
            :title="$t('birthdate')"
            :imgName="'date.svg'"
          />
          <DataLabelGroup
            :value="employeeData.employeeNationalNumber"
            :title="$t('nationalNumber')"
            :imgName="'nationalNumber.svg'"
          />
          <DataLabelGroup
            :value="employeeData.employeePassportNumber"
            :title="$t('passportNumber')"
            :imgName="'passportNumber.svg'"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BASE_URL, DEFAULT_IMG } from "@/utils/constants";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      BASE_URL,
      DEFAULT_IMG,
    };
  },
  components: {
    DataLabelGroup,
  },
  props: ["employeeData"],
  methods: {},
  computed: {},
};
</script>
